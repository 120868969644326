import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/templates/layout"
import SEO from "../components/templates/seo"

import Hero from "../components/organisms/hero"

const CompanyPage = () => {
  return (
    <Layout pageInfo={{ pageName: "company" }}>
      <SEO title="MissionMe - Für Unternehmen" />
      <Container fluid="md">
        <Hero
          showDots
          headingPreHeadline="Für Unternehmen"
          headingHeadline="Glückliche Mitarbeiter*innen machen erfolgreiche Unternehmen"
          introHeadline="Mit uns zusammen Arbeiten"
          introText={'Ganz gleich, ob Sie Ihren Mitarbeitern am Tage einen achtsamen Umgang mit Geist und Körper ermöglichen oder Erholung in der Nacht schenken wollen - wir machen es möglich. Unsere Apps integrieren sich dabei bestmöglich in den Alltag und ins Arbeitsleben. In kurzen Audio-Einheiten und Kursen begleiten wir Schritt-für-Schritt auf dem Weg zu guten Gewohnheiten und einem gesünderen Lebensstil. Wissenschaftlich fundiert, evidenzbasiert und unterhaltsam.\n\nErfahren Sie noch heute, wie auch Ihr Unternehmen von unseren Apps & Experten profitieren kann.'}
          introImg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.575 396.85">
          <g transform="translate(597.264 -1155.03)">
              <path d="M-523.105,1405.933v-154.94h-74.159v229.1h229.1v-74.159Z" fill="#662483" className="bigL" />
              <path
                  d="M-396.682,1531.988a82.173,82.173,0,0,1-82.079-82.079,82.173,82.173,0,0,1,82.079-82.08,82.173,82.173,0,0,1,82.078,82.08A82.172,82.172,0,0,1-396.682,1531.988Zm0-160.43a78.439,78.439,0,0,0-78.351,78.351,78.44,78.44,0,0,0,78.351,78.351,78.439,78.439,0,0,0,78.35-78.351A78.439,78.439,0,0,0-396.682,1371.558Z"
                  fill="#009e7b" className="lightcircle"/>
              <path
                  d="M-396.682,1541.934a92.13,92.13,0,0,1-92.025-92.025,92.13,92.13,0,0,1,92.025-92.025,92.129,92.129,0,0,1,92.025,92.025A92.129,92.129,0,0,1-396.682,1541.934Zm0-180.322a88.4,88.4,0,0,0-88.3,88.3,88.4,88.4,0,0,0,88.3,88.3,88.4,88.4,0,0,0,88.3-88.3A88.4,88.4,0,0,0-396.682,1361.612Z"
                  fill="#009e7b" className="lightcircle"/>
              <path
                  d="M-396.682,1551.88a102.088,102.088,0,0,1-101.972-101.971,102.088,102.088,0,0,1,101.972-101.971,102.088,102.088,0,0,1,101.971,101.971A102.088,102.088,0,0,1-396.682,1551.88Zm0-200.214a98.355,98.355,0,0,0-98.244,98.243,98.355,98.355,0,0,0,98.244,98.242,98.353,98.353,0,0,0,98.243-98.242A98.354,98.354,0,0,0-396.682,1351.666Z"
                  fill="#009e7b" className="lightcircle"/>
              <path d="M-105.37,1379.111A162.129,162.129,0,0,0-267.5,1217.032a162.128,162.128,0,0,0-162.128,162.079Z"
                  fill="#ea504c" className="halfcircle"/> />
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-521.427 1155.03)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-497.562 1170.17)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-473.697 1185.311)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-449.832 1200.452)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-425.967 1215.593)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-402.102 1230.733)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-378.237 1245.874)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-354.372 1261.015)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-330.507 1276.155)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-306.642 1291.296)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-282.777 1306.437)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-258.912 1321.577)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-235.047 1336.718)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-139.586 1397.281)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-115.721 1412.422)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-91.856 1427.562)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-67.991 1442.703)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-211.182 1351.859)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-187.316 1366.999)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-163.451 1382.14)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-520.345 1179.675)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-496.48 1194.815)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-472.615 1209.956)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-448.75 1225.097)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-424.885 1240.237)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-401.02 1255.378)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-377.155 1270.519)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-353.29 1285.659)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-329.425 1300.8)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-305.56 1315.941)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-281.695 1331.082)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-257.83 1346.222)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-233.965 1361.363)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-138.505 1421.926)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-114.64 1437.066)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-90.775 1452.207)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-66.91 1467.348)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-210.1 1376.504)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-186.235 1391.644)" fill="#662483" className="contactpoint"/>
              <circle cx="4.11" cy="4.11" r="4.11" transform="translate(-162.37 1406.785)" fill="#662483" className="contactpoint"/>
          </g>
      </svg>}
        />

      <a href="mailto:sho@missionme.de?subject=B2B Kooperationsanfrage MissionMe" className="btn btn-primary">Mehr Informationen anfordern</a>
      </Container>
    </Layout>
  )
}

export default CompanyPage
